import * as React from "react";
import styles from "./styles.module.sass";
import { useCallback } from "react";
import { useSelect } from "../../../../../hooks/useSelect";
import { PickupStatus } from "../../../../../types/shared";

export interface ISelectItemProps {
  activePickup: boolean;
  activeDriveThru?: boolean;
  activeDelivery: boolean;
  item: { name: string; value: string };
  onClick: (
    item: { name: string; value: string },
    type: string,
    newVal: boolean
  ) => void;
}

export const SelectItem: React.FC<ISelectItemProps> = (props) => {
  const { item, activeDelivery, activePickup, onClick, activeDriveThru } = props;
  const { store } = useSelect(state => ({
    store: state.storeReducer.store
  }))
  const handlePickupClick = useCallback(() => {
    onClick(item, "Pickup", !activePickup);
  }, [onClick, item, activePickup]);
  const handleDeliveryClick = useCallback(() => {
    onClick(item, "Delivery", !activeDelivery);
  }, [onClick, item, activeDelivery]);

  const handleDriveThruClick = useCallback(() => {
    if (activePickup) {
      onClick(item, "DriveThru", !activeDriveThru);
    }
  }, [onClick, item, activeDriveThru, activePickup]);
  return (
    <div
      className={`${styles.main} ${activeDelivery || activePickup ? styles.active : ""
        }`}
    >
      <div className={styles.text}>{item.name}</div>
      <div className={styles.checkBoxWrapper}>
        <div
          className={`${styles.checkBox} ${activePickup ? styles.activePickup : ""
            } ${styles.leftCheckBox}`}
          onClick={handlePickupClick}
        >
          <img src={require("../../../assets/path.svg").default} />
        </div>
        {
          store.drive_thru_status === PickupStatus.service_enabled && (
            <div
              className={`${styles.checkBox} ${activeDriveThru ? styles.activePickup : !activePickup ? styles.disable : ""
                } ${styles.leftCheckBox}`}
              onClick={handleDriveThruClick}
            >
              <img src={require("../../../assets/path.svg").default} />
            </div>
          )
        }
        <div
          className={`${styles.checkBox} ${activeDelivery ? styles.activeDelivery : ""
            } ${styles.rightCheckBox}`}
          onClick={handleDeliveryClick}
        >
          <img src={require("../../../assets/path.svg").default} />
        </div>
      </div>
    </div>
  );
};
